import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAuth } from 'firebase/auth';
import { logEvent, getAnalytics } from 'firebase/analytics';
import PatientList from './PatientList';
import AccountDetails from './AccountDetails';
import './MainView.css';
import Premium from './Premium';

const MainView = ({ userId, premiumTime }) => {
    const { t } = useTranslation();
    const [view, setView] = useState('account');
    const auth = getAuth();
    const analytics = getAnalytics();
    const [showPremiumModal, setShowPremiumModal] = useState(false);

    useEffect(() => {
        logEvent(analytics, 'app_open');
        
        const checkPremium = () => {
            let timestamp = Date.now();
            return premiumTime >= timestamp;
        };
    
        if (checkPremium()) {
            setView('patients');
        } else {
            setView('premium');
        }
    }, [analytics, premiumTime]);    

    const handleLogout = () => {
        auth.signOut().then(() => {
            window.location.href = '/';
        }).catch((error) => {
            console.error(t('error'), error);
        });
    };

    const changeView = (view) => {
        if (view === 'patients') {
            let timestamp = Date.now();
            let isPremium = premiumTime >= timestamp;
            if (isPremium) {
                setView(view)
            } else {
                setShowPremiumModal(true)
            }
        } else {
            setView(view)
        }
    }

    const handlePremiumAccess = () => {
        setView('premium');
        setShowPremiumModal(false);
    };

    return (
        <div className="mainview-container-mainview">
            {/* Top Bar */}
            <div className="topbar-mainview">
                <div className="topbar-logo-mainview">{t('appName')}</div>
                <div className="topbar-right-mainview">
                    <button className="topbar-button-mainview" onClick={() => changeView('patients')}>{t('patients')}</button>
                    <button className="topbar-button-mainview" onClick={() => changeView('account')}>{t('account')}</button>
                    <button className="topbar-button-mainview" onClick={() => changeView('premium')}>{t('premium')}</button>
                    <button className="topbar-logout-mainview" onClick={handleLogout}>{t('logout')}</button>
                </div>
            </div>

            {/* Main Content */}
            <div className="mainview-content-mainview">
                {view === 'patients' ? 
                    <PatientList userId={userId} premiumTime={premiumTime} setView={changeView} /> 
                :   
                    view === 'account' ?
                        <AccountDetails userId={userId} premiumTime={premiumTime} />
                    :
                        <Premium userId={userId} premiumTime={premiumTime} />
                }
            </div>

            {showPremiumModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t("premium_access_required")}</h2>
                        <p>{t("not_have_access")}</p>
                        <div className="modal-buttons">
                            <button onClick={() => setShowPremiumModal(false)}>{t("close")}</button>
                            <button onClick={handlePremiumAccess}>{t("premium")}</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainView;
