import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './TermsOfService.css';

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="privacy-policy-terms">
            <div className="top-bar-terms">
                <button className="back-button-terms" onClick={() => navigate(-1)}>
                    &#8592;
                </button>
                <h1 className="app-title-terms">Podana</h1>
            </div>
            <div className="content-terms">
                <h2>{t('privacy.title')}</h2>
                <p>{t('privacy.introduction')}</p>
                <h3>{t('privacy.informationWeCollectTitle')}</h3>
                <p>{t('privacy.informationWeCollect')}</p>
                <h3>{t('privacy.howWeUseTitle')}</h3>
                <p>{t('privacy.howWeUse')}</p>
                <h3>{t('privacy.dataStorageTitle')}</h3>
                <p>{t('privacy.dataStorage')}</p>
                <h3>{t('privacy.cookiesTitle')}</h3>
                <p>{t('privacy.cookies')}</p>
                <h3>{t('privacy.accountCreationTitle')}</h3>
                <p>{t('privacy.accountCreation')}</p>
                <h3>{t('privacy.inAppPurchasesTitle')}</h3>
                <p>{t('privacy.inAppPurchases')}</p>
                <h3>{t('privacy.changesTitle')}</h3>
                <p>{t('privacy.changes')}</p>
                <h3>{t('privacy.contactTitle')}</h3>
                <p>{t('privacy.contact')}</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
