import React, { useEffect, useState } from 'react';
import { getFirestore, collection, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import './PatientList.css';
import PatientOptions from './PatientOptions';
import PatientProfile from './patient/PatientProfile';
import Notes from './patient/Notes';
import Monitorings from './patient/Monitorings';
import Podopathologies from './patient/Podopathologies';
import Nailshape from './patient/Nailshape';
import ClinicalData from './patient/ClinicalData';
import Hands from './patient/Hands';
import Feet from './patient/Feet';

const PatientList = ({ userId, premiumTime, setView }) => {
  
  const { t } = useTranslation();
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newPatientName, setNewPatientName] = useState('');
  const [newPatientPhone, setNewPatientPhone] = useState('');
  const [loading, setLoading] = useState(true);

  const [activeSection, setActiveSection] = useState('options');

  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSectionChange = (section) => {
      setActiveSection(section);
  };

  const db = getFirestore();

  useEffect(() => {
    const q = query(collection(db, `users/${userId}/patients`));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const patientsList = snapshot.docs.map(doc => {
        const patientData = doc.data();
        // Verifica se o nome existe antes de converter para lowercase
        const patientName = patientData.name ? patientData.name.toLowerCase() : '';
        return { id: doc.id, ...patientData, name: patientName };
      });
  
      // Ordena a lista de pacientes por nome
      const sortedPatientsList = patientsList.sort((a, b) => a.name.localeCompare(b.name));
  
      setPatients(sortedPatientsList);
      setFilteredPatients(sortedPatientsList);
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [db, userId]);
  
  // Função de busca
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = patients.filter(patient => 
      // Verifica se o nome do paciente existe antes de chamar toLowerCase()
      patient.name && patient.name.toLowerCase().includes(query)
    );
    setFilteredPatients(filtered);
  };  

  const handleAddPatient = async () => {
    //setIsPopupOpen(false);
    if (newPatientName.trim() !== '') {
      try {
        await addDoc(collection(db, `users/${userId}/patients`), {
          name: newPatientName.trim(),
          phone: newPatientPhone.trim(),
          url_image: ''
        });
        setIsPopupOpen(false);
        setNewPatientName('');
        setNewPatientPhone('');
      } catch (error) {
        setPopupMessage(`${t('error')}: ${error.message}`);
        setShowPopup(true)
      }
    } else {
      setPopupMessage(t('patient_name_required'));
      setShowPopup(true)
    }
  };

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient);
    handleSectionChange('options');
  };

  const renderActiveSection = () => {
    switch (activeSection) {
        case 'profile':
          return <PatientProfile 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'clinicalData':
          return <ClinicalData 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'nailShape':
          return <Nailshape 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'hands':
          return <Hands 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'feet':
          return <Feet 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'podopathologies':
          return <Podopathologies 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'notes':
          return <Notes 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        case 'monitorings':
          return <Monitorings 
            userId={userId} 
            patientId={selectedPatient.id}
            onSectionChange={handleSectionChange}  
            />;
        default:
            return (
                <PatientOptions 
                    selectedPatient={selectedPatient} 
                    onSectionChange={handleSectionChange} 
                    premiumTime={premiumTime}
                    setView={setView}
                    setSelectedPatient={setSelectedPatient}
                />
            );
    }
  };

  const closePopup = () => {
      setShowPopup(false);
  };

  if (loading) {
    return (
      <div className="loading-container-account">
        <div className="progress-bar-account"></div>
      </div>
    );
  }

  return (
    <div className="patientlist-container">
      {/* Patient List Side */}
      <div className="patientlist">
        <div className="patientlist-header">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder={t('search_patients')}  
            className="search-patient-input"
          />
          <button className="add-patient-button" onClick={() => setIsPopupOpen(true)}>
            {t('add_patient')}  
          </button>
        </div>

        <div className="patientlist-list">
          {filteredPatients.map(patient => (
            <div key={patient.id} className="patient-card" onClick={() => handlePatientClick(patient)}>
              {
                patient.url_image !== "" ?
                  <img src={patient.url_image} alt={patient.name} className="patient-image" />
                :
                  <img src={require('../images/logo.png')} alt={patient.name} className="patient-image" style={{ objectFit: 'contain' }} />
              }
              <div className="patient-details">
                <div className="patient-name" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{patient.name}</div>
                <div className="patient-phone">{patient.phone || ''}</div> 
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Details Side */}
      <div className="details-container">
          {selectedPatient ? renderActiveSection() : <p>{t('no_patient_selected')}</p>}
      </div>

      {/* Popup for adding a new patient */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={() => setIsPopupOpen(false)}>X</button>
            <h3>{t('add_new_patient')}</h3>
            <input
              type="text"
              value={newPatientName}
              onChange={(e) => setNewPatientName(e.target.value)}
              placeholder={t('patient_name')}  
              required
            />
            <input
              type="text"
              value={newPatientPhone}
              onChange={(e) => setNewPatientPhone(e.target.value)}
              placeholder={t('patient_phone')}  
            />
            <button className="add-patient-button" onClick={handleAddPatient}>
              {t('add_patient')}  
            </button>
          </div>
        </div>
      )}
                
      {showPopup && (
          <div className="popup-error">
              <div className="popup-content-error">
                  <p>{popupMessage}</p>
                  <button onClick={closePopup} className="close-popup-error">{t('close')}</button>
              </div>
          </div>
      )}
    </div>
  );
};

export default PatientList;
