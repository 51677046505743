import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './Nailshape.css'; // Arquivo de estilo para esta página
import normal from '../../images/dedonormal.png';
import incorreto from '../../images/dedoincorreto.png';
import involuta from '../../images/dedoinvoluta.png';
import telha from '../../images/dedotelha.png';
import funil from '../../images/dedofunil.png';
import caracol from '../../images/dedocaracol.png';
import cunha from '../../images/dedocunha.png';
import gancho from '../../images/dedogancho.png';
import { useTranslation } from 'react-i18next';

const Nailshape = ({ userId, patientId, onSectionChange }) => {
  const [nailshape, setNailshape] = useState('0'); // Valor inicial como "Normal"
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const images = [
    { src: normal, name: t('nailShape.normal') },
    { src: incorreto, name: t('nailShape.incorrect') },
    { src: involuta, name: t('nailShape.involuted') },
    { src: telha, name: t('nailShape.tile') },
    { src: funil, name: t('nailShape.funnel') },
    { src: caracol, name: t('nailShape.snail') },
    { src: cunha, name: t('nailShape.wedge') },
    { src: gancho, name: t('nailShape.hook') }
  ];

  const db = getFirestore();

  useEffect(() => {
    // Carregar os dados existentes do Firestore
    const fetchData = async () => {
      const docRef = doc(db, `users/${userId}/patients/${patientId}/nail_shape/data`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setNailshape(docSnap.data().nailshape); // Atualiza com os dados existentes
      }
      setLoading(false);
    };

    fetchData();
  }, [userId, patientId, db]);

  const handleUpdate = async () => {
    setLoading(true);

    try {
      const docRef = doc(db, `users/${userId}/patients/${patientId}/nail_shape/data`);
      const docSnap = await getDoc(docRef);

      const data = { nailshape };

      if (docSnap.exists()) {
        await updateDoc(docRef, data); // Atualiza os dados existentes
      } else {
        await setDoc(docRef, data); // Cria novo documento
      }
      setPopupMessage(t('successfully_updated'));
    } catch (error) {
        setPopupMessage(`${t('error')}: ${error.message}`);
    } finally {
        setLoading(false);
        setShowPopup(true);
    }
  };

  const handleSelectionChange = (value) => {
    setNailshape(value);
  };

  const closePopup = () => {
      setShowPopup(false);
  };

  if (loading) {
    return (
      <div className="loading-container-account">
        <div className="progress-bar-account"></div>
      </div>
    );
  }

  return (
    <div className="nailshape-content">
        <div className="nailshape-header">
            <div className="nailshape-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
        </div>

        {/* Imagem selecionada */}
        <div className="nailshape-selected">
            <img src={images[nailshape].src} alt={images[nailshape].name} style={{ width: '150px', height: '150px' }} />
            <div className="nailshape-name">{images[nailshape].name}</div>
        </div>

        {/* Opções de seleção */}
        <div className="nailshape-options">
            {images.map((image, index) => (
                <div key={index} className="nailshape-option">
                <input
                    type="radio"
                    id={`nailshape-${index}`}
                    name="nailshape"
                    value={index}
                    checked={nailshape === `${index}`}
                    onChange={() => handleSelectionChange(`${index}`)}
                />
                <label htmlFor={`nailshape-${index}`}>
                    <img src={image.src} alt={image.name} style={{ width: '75px', height: '75px' }} />
                    <span>{image.name}</span>
                </label>
                </div>
            ))}
        </div>  

      {/* Botão de atualização */}
      <button onClick={handleUpdate} className="update-button">
        {t('update')}
      </button>
                
      {showPopup && (
          <div className="popup">
              <div className="popup-content">
                  <p>{popupMessage}</p>
                  <button onClick={closePopup} className="close-popup">{t('close')}</button>
              </div>
          </div>
      )}
    </div>
  );
};

export default Nailshape;