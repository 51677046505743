import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { httpsCallable, getFunctions } from 'firebase/functions';
import './PatientOptions.css';

const PatientOptions = ({ selectedPatient, onSectionChange, premiumTime, setView, setSelectedPatient }) => {
    const { t } = useTranslation();
    const auth = getAuth();
    const [showPremiumModal, setShowPremiumModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    
    const handleSectionClick = (section) => {
        const currentTime = Date.now();

        // Seções que requerem premium
        const premiumSections = ['hands', 'feet', 'monitorings'];

        if (premiumSections.includes(section) && Number(premiumTime) <= currentTime) {
            setShowPremiumModal(true);
            return;
        }

        if (onSectionChange) {
            onSectionChange(section);
        }
    };

    const handlePremiumAccess = () => {
        setView('premium');
        setShowPremiumModal(false);
    };

    const handleDeletePatient = () => {
        setShowDeleteModal(true);
    };

    const confirmDeletePatient = async () => {
        const user = auth.currentUser; // O usuário autenticado que deseja excluir o paciente

        try {
            // Tente fazer login com a senha fornecida
            await signInWithEmailAndPassword(auth, user.email, password);
            setLoading(true);
            
            // Se a senha estiver correta, chame a função de exclusão
            const data = { uid: user.uid, patientId: selectedPatient.id };
            const functions = getFunctions();
            const deletePatientFunction = httpsCallable(functions, "deletePatient");
            await deletePatientFunction(data);
            
            setSelectedPatient(null)
            
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
        } finally {
            setLoading(false);
            setShowDeleteModal(false);
            setPassword('');
            setShowPopup(true)
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const closeDeleteModal = () => {
        setPassword('')
        setShowDeleteModal(false)
    }

    return (
        <div className="patient-details-content">
            {selectedPatient.url_image !== "" ? (
                <img 
                    src={selectedPatient.url_image} 
                    alt={selectedPatient.name} 
                    className="patient-detail-image" 
                />
            ) : (
                <img 
                    src={require('../images/logo.png')} 
                    alt={selectedPatient.name} 
                    className="patient-detail-image" 
                    style={{ objectFit: 'contain' }} 
                />
            )}
            
            <h2 className="patient-detail-name" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{selectedPatient.name}</h2>
            <p className="patient-detail-phone">{selectedPatient.phone || ''}</p>

            <div className="patient-sections">
                <button className="patient-section-button" onClick={() => handleSectionClick('profile')}>{t('patientSections.profile')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('clinicalData')}>{t('patientSections.clinicalData')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('nailShape')}>{t('patientSections.nailshape')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('hands')}>{t('patientSections.hands')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('feet')}>{t('patientSections.feet')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('podopathologies')}>{t('patientSections.podopathologies')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('notes')}>{t('patientSections.notes')}</button>
                <button className="patient-section-button" onClick={() => handleSectionClick('monitorings')}>{t('patientSections.monitorings')}</button>
                <button className="patient-section-button" onClick={handleDeletePatient} style={{ background: 'red' }}>
                    {t('patientSections.deletePatient')}
                </button>
            </div>

            {showPremiumModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t("premium_access_required")}</h2>
                        <p>{t("not_have_access")}</p>
                        <div className="modal-buttons">
                            <button onClick={() => setShowPremiumModal(false)}>{t("close")}</button>
                            <button onClick={handlePremiumAccess}>{t("premium")}</button>
                        </div>
                    </div>
                </div>
            )}

            {showDeleteModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 style={{ textTransform: 'capitalize' }}>{selectedPatient.name}</h2>
                        <h2>{`${t("confirm_delete")}`}</h2>
                        <input 
                            type="password" 
                            placeholder={t("currentPassword")} 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                        />
                        <div className="modal-buttons">
                            <button onClick={closeDeleteModal} disabled={loading}>{t("close")}</button>
                            <button onClick={confirmDeletePatient} disabled={loading || password.length < 6}
                                style={{ textTransform: 'uppercase', backgroundColor: password.length < 6 ? '#ccc': 'red' }}>
                                {loading ? t("deleting") : t("patientSections.deletePatient")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
                
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={closePopup} className="close-popup">{t('close')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PatientOptions;
