import { useEffect, useState } from 'react';
import './FeetDots.css';
import { useTranslation } from 'react-i18next';
import { 
    collection, 
    doc, 
    addDoc, 
    deleteDoc, 
    onSnapshot, 
    getFirestore 
} from 'firebase/firestore';

const db = getFirestore();

function LeftFootPlantar({ userId, patientId }) {
    const [isColor, setIsColor] = useState(null);
    const [dots, setDots] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (userId && patientId) {
            const plantarCollection = collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'feet', 'leftFoot', 'plantar'
            );

            const unsubscribe = onSnapshot(plantarCollection, (snapshot) => {
                const ballsD = snapshot.docs.map(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    return data;
                });
                setDots(ballsD);
                setLoading(false)
            });

            return () => unsubscribe();
        }
    }, [userId, patientId]);

    function dot(event) {
        if (isColor) {
            let x = event.nativeEvent.offsetX;
            let y = event.nativeEvent.offsetY;

            const plantarCollection = collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'feet', 'leftFoot', 'plantar'
            );

            addDoc(plantarCollection, { isColor, x, y })
                .then(docRef => {
                    setDots([...dots, { isColor, x, y, id: docRef.id }]);
                })
                .catch((error) => {
                    console.error('Erro ao adicionar documento: ', error);
                });
        } else {
            alert(t('feet.selProblem'));
        }
    }

    function removeBall(ballId) {
        const ballDocRef = doc(
            collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'feet', 'leftFoot', 'plantar'
            ), 
            ballId
        );

        deleteDoc(ballDocRef)
            .then(() => {
                setDots(dots.filter((ball) => ball.id !== ballId));
            })
            .catch((error) => {
                console.error('Erro ao remover documento: ', error);
            });
    }

    if (loading) {
        return (
        <div className="loading-container-account">
            <div className="progress-bar-account"></div>
        </div>
        );
    }

    return (
        <div className='patientLeftFoot'>
            <ul className="symptomList">
                <li style={{ backgroundColor: 'red' }} onClick={() => setIsColor('red')}>
                    {t('feet.wart')}
                </li>
                <li style={{ backgroundColor: 'grey' }} onClick={() => setIsColor('grey')}>
                    {t('feet.callusWithCore')}
                </li>
                <li style={{ backgroundColor: 'orange' }} onClick={() => setIsColor('orange')}>
                    {t('feet.callusWithoutCore')}
                </li>
                <li style={{ backgroundColor: 'deeppink' }} onClick={() => setIsColor('deeppink')}>
                    {t('feet.fissures')}
                </li>
            </ul>
            <ul className="symptomList">
                <li style={{ backgroundColor: 'purple' }} onClick={() => setIsColor('purple')}>
                    {t('feet.keratosis')}
                </li>
                <li style={{ backgroundColor: 'brown' }} onClick={() => setIsColor('brown')}>
                    {t('feet.ringworm')}
                </li>
                <li style={{ backgroundColor: 'darkgreen' }} onClick={() => setIsColor('darkgreen')}>
                    {t('feet.ulcer')}
                </li>
                <li style={{ backgroundColor: 'darkblue' }} onClick={() => setIsColor('darkblue')}>
                    {t('feet.dehydrosis')}
                </li>
            </ul>
            <ul className="symptomList">
                <li style={{ backgroundColor: 'limegreen' }} onClick={() => setIsColor('limegreen')}>
                    {t('feet.onychocryptosis')}
                </li>
                <li style={{ backgroundColor: 'tomato' }} onClick={() => setIsColor('tomato')}>
                    {t('feet.onychomycosis')}
                </li>
                <li style={{ backgroundColor: 'darkcyan' }} onClick={() => setIsColor('darkcyan')}>
                    {t('feet.onycholysis')}
                </li>
                <li style={{ backgroundColor: 'black' }} onClick={() => setIsColor('black')}>
                    {t('feet.psoriasis')}
                </li>
            </ul>

            <div className='imgAndDots'>
                <img
                    src={require('../../../images/pe-esq-baixo.png')}
                    onClick={dot}
                    alt={t('feet.leftFoot')}
                />
                {dots.map((dotD, i) => (
                    <div
                        key={i}
                        className="dot"
                        style={{
                            top: dotD.y - 7,
                            left: dotD.x - 6,
                            position: 'absolute',
                            backgroundColor: dotD.isColor
                        }}
                        onClick={() => removeBall(dotD.id)}
                    />
                ))}
            </div>
        </div>
    );
}

export default LeftFootPlantar;