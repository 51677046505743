import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, getDoc, setDoc, updateDoc, getFirestore } from 'firebase/firestore';
import './FeetInfo.css';
import RadioButtonGroup from '../RadioButtonGroup';

const FeetInfo = ({ userId, patientId }) => {
    const { t } = useTranslation();
    const db = getFirestore();
    
    const [shoeSize, setShoeSize] = useState('');
    const [hygiene, setHygiene] = useState(true);
    const [suitable, setSuitable] = useState(true);
    const [sockType, setSockType] = useState(0);
    const [footwearType, setFootwearType] = useState(0);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        // Carregar os dados existentes do Firestore
        const fetchData = async () => {
            const docRef = doc(db, `users/${userId}/patients/${patientId}/feet/data`);
            const docSnap = await getDoc(docRef);
        
            if (docSnap.exists()) {
                const data = docSnap.data();
                setShoeSize(data.size || '');
                setHygiene(data.hygiene);
                setSuitable(data.suitable);
                setSockType(data.sockType);
                setFootwearType(data.footwearType);
                console.log(data)
            }
            setLoading(false);
        };
    
        fetchData();
      }, [userId, patientId, db]);

    const handleUpdate = async () => {
        setLoading(true)

        try {
            const docRef = doc(db, `users/${userId}/patients/${patientId}/feet/data`);
            const docSnap = await getDoc(docRef); // Verifica se o documento já existe
        
            if (docSnap.exists()) {
                // Se o documento existe, faz o update
                await updateDoc(docRef, {
                    size: shoeSize,
                    hygiene,
                    suitable,
                    sockType,
                    footwearType,
                });
                console.log('Dados atualizados com sucesso!');
            } else {
                // Se o documento não existe, cria um novo documento
                await setDoc(docRef, {
                    size: shoeSize,
                    hygiene,
                    suitable,
                    sockType,
                    footwearType,
                });
                console.log('Documento criado com sucesso!');
            }
            setPopupMessage(t('successfully_updated'));
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
        } finally {
            setLoading(false);
            setShowPopup(true);
        }
    };

    if (loading) {
        return (
          <div className="loading-container-account">
            <div className="progress-bar-account"></div>
          </div>
        );
    }

    return (
        <div className="feet-info-container">

            <div className="input-group">
                <label>{t('feetInfo.shoeSize')}</label>
                <input 
                    type="text" 
                    value={shoeSize} 
                    onChange={(e) => setShoeSize(e.target.value)} 
                    placeholder={t('feetInfo.enterShoeSize')} 
                />
            </div>

            <RadioButtonGroup
                title={t("feetInfo.hygiene")}
                selectedValue={hygiene}
                onChange={(value) => setHygiene(value)}
            />

            <RadioButtonGroup
                title={t("feetInfo.suitable")}
                selectedValue={suitable}
                onChange={(value) => setSuitable(value)}
            />

            <div className="form-group">
                <label>{t("feetInfo.sockType")}</label>
                <div className="radio-group vertical">
                    <label>
                        <input
                            type="radio"
                            checked={sockType === 0}
                            onChange={() => setSockType(0)}
                        />
                        {t("feetInfo.socialSocks")}
                    </label>
                    <label>
                        <input
                            type="radio"
                            checked={sockType === 1}
                            onChange={() => setSockType(1)}
                        />
                        {t("feetInfo.sportsSocks")}
                    </label>
                    <label>
                        <input
                            type="radio"
                            checked={sockType === 2}
                            onChange={() => setSockType(2)}
                        />
                        {t("feetInfo.compressionSocks")}
                    </label>
                </div>
            </div>

            <hr className="divider" />

            <div className="form-group">
                <label>{t("feetInfo.footwearType")}</label>
                <div className="radio-group vertical">
                    <label>
                        <input
                            type="radio"
                            checked={footwearType === 0}
                            onChange={() => setFootwearType(0)}
                        />
                        {t("feetInfo.closedShoes")}
                    </label>
                    <label>
                        <input
                            type="radio"
                            checked={footwearType === 1}
                            onChange={() => setFootwearType(1)}
                        />
                        {t("feetInfo.openShoes")}
                    </label>
                </div>
            </div>

            <hr className="divider" />

            <button className="update-button" onClick={handleUpdate}>
                {t("update")}
            </button>
                    
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)} className="close-popup">{t('close')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeetInfo;
