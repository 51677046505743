import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFirestore, collection, query, orderBy, doc, addDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import './Notes.css';

const Notes = ({ userId, patientId, onSectionChange }) => {
    
    const handleSectionClick = (section) => {
        if (onSectionChange) {
            onSectionChange('options');
        }
    };

    const { t } = useTranslation();
    const [annotations, setAnnotations] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);
    const [deleteNoteId, setDeleteNoteId] = useState(null);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [newAnnotation, setNewAnnotation] = useState("");
    const [newDate, setNewDate] = useState("");
    const [loading, setLoading] = useState(true);

    const db = getFirestore();

    useEffect(() => {
        const annotationsRef = collection(db, `users/${userId}/patients/${patientId}/annotations`);
        const q = query(annotationsRef, orderBy("date", "desc"));
        
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const notes = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setAnnotations(notes);
            setLoading(false)
        });

        return () => unsubscribe();
    }, [db, userId, patientId]);

    const handleDelete = async () => {
        await deleteDoc(doc(db, `users/${userId}/patients/${patientId}/annotations`, deleteNoteId));
        setDeleteNoteId(null);
    };

    const handleAddNote = async () => {
        if (newAnnotation && newDate) {
            await addDoc(collection(db, `users/${userId}/patients/${patientId}/annotations`), {
                annotations: newAnnotation,
                date: newDate,
                timestamp: Date.now()
            });
            setNewAnnotation("");
            setNewDate("");
            setIsAddPopupOpen(false);
        }
    };

    if (loading) {
      return (
        <div className="loading-container-account">
          <div className="progress-bar-account"></div>
        </div>
      );
    }

    return (
        <div className="notes-container">
            <div className="notes-header">
                <div className="notes-close-icon" onClick={handleSectionClick}>{`<- ${t('back')}`}</div>
                <button className="notes-add-btn" onClick={() => setIsAddPopupOpen(true)}>
                    {t('addNote')}
                </button>
            </div>

            <div className="notes-grid">
                {annotations.map(note => (
                    <div className="notes-portrait" key={note.id}>
                        <div className="notes-delete-icon" onClick={() => setDeleteNoteId(note.id)}>🗑️</div>
                        <div className="notes-content" onClick={() => setSelectedNote(note)}>
                            {note.annotations.substring(0, 150)}...
                        </div>
                        <div className="notes-footer">
                            <div className="notes-date">{note.date}</div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Popup for note details */}
            {selectedNote && (
                <div className="notes-popup-background" onClick={() => setSelectedNote(null)}>
                    <div className="notes-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="notes-popup-close" onClick={() => setSelectedNote(null)}>X</div>
                        <div className="notes-popup-text">
                            {selectedNote.annotations}
                        </div>
                    </div>
                </div>
            )}

            {/* Delete confirmation popup */}
            {deleteNoteId && (
                <div className="notes-popup-background" onClick={() => setDeleteNoteId(null)}>
                    <div className="notes-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="notes-popup-close" onClick={() => setDeleteNoteId(null)}>X</div>
                        <div className="notes-popup-text">
                            {t('deleteMessageNotes')}
                        </div>
                        <div className="notes-popup-actions">
                            <button className="notes-btn notes-btn-cancel" onClick={() => setDeleteNoteId(null)}>
                                {t('cancel')}
                            </button>
                            <button className="notes-btn notes-btn-delete" onClick={handleDelete}>
                                {t('delete')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add new annotation popup */}
            {isAddPopupOpen && (
                <div className="notes-popup-background" onClick={() => setIsAddPopupOpen(false)}>
                    <div className="notes-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="notes-popup-close" onClick={() => setIsAddPopupOpen(false)}>X</div>
                        <textarea 
                            className="notes-new-annotation"
                            placeholder={t('newNote')}
                            value={newAnnotation}
                            onChange={(e) => setNewAnnotation(e.target.value)}
                        />
                        <input 
                            className="notes-new-date"
                            type="date"
                            value={newDate}
                            onChange={(e) => setNewDate(e.target.value)}
                        />
                        <button className="notes-btn notes-btn-add" onClick={handleAddNote}>
                            {t('add')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notes;
