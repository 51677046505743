import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from './RadioButtonGroup';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './PodopathologiesClinical.css';  // Para o CSS customizado
import RadioButtonThree from './RadioButtonThree';

const ClinicalData = ({ userId, patientId, onSectionChange }) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const [clinicalData, setClinicalData] = useState({
        alcoholic: false,
        allergies: false,
        allergieswhat: [],
        cardiov: false,
        hypertension: false,
        circulatoryProblems: false,
        heartDisease: false,
        pacemaker: false,
        diabetes: false,
        diabetestime: "",
        diabetestype: "",
        ophtalm: false,
        othersclin: "",
        renal: false,
        smoking: false,
        dermatitis: false,
        psoriasis: false,
        hiv: false,
        hepatitis: false,
        highUricAcid: false,
        cancerHistory: false,
        leprosy: false,
        painSensitivity: 2,
        medication: false,
        medicationList: ""
    });

    const db = getFirestore();

    useEffect(() => {
        
        const fetchData = async () => {
            const docRef = doc(db, `users/${userId}/patients/${patientId}/clinical_data/data`);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                
                setClinicalData(prevData => ({
                    ...prevData,
                    alcoholic: data.alcoholic,
                    allergies: data.allergies,
                    allergieswhat: data.allergieswhat,
                    cardiov: data.cardiov,
                    hypertension: data.hypertension,
                    circulatoryProblems: data.circulatoryProblems,
                    heartDisease: data.heartDisease,
                    pacemaker: data.pacemaker,
                    diabetes: data.diabetes,
                    diabetestime: data.diabetestime,
                    diabetestype: data.diabetestype,
                    ophtalm: data.ophtalm,
                    othersclin: data.othersclin,
                    renal: data.renal,
                    smoking: data.smoking,
                    dermatitis: data.dermatitis === 0,
                    psoriasis: data.psoriasis === 0,
                    hiv: data.hiv === 0,
                    hepatitis: data.hepatitis === 0,
                    highUricAcid: data.highUricAcid = 0,
                    cancerHistory: data.cancerHistory === 0,
                    leprosy: data.leprosy === 0,
                    painSensitivity: data.painSensitivity,
                    medication: data.medication === 0,
                    medicationList: data.medicationList
                }));
            }
            setLoading(false)
        };

        fetchData();
    }, [userId, patientId, db]);

    const handleSave = async () => {
        setLoading(true)
        
        try {
        
            const docRef = doc(db, `users/${userId}/patients/${patientId}/clinical_data/data`);

            const docSnap = await getDoc(docRef);

            const dataToSave = {
                ...clinicalData,
                dermatitis: clinicalData.dermatitis ? 0 : 1,
                psoriasis: clinicalData.psoriasis ? 0 : 1,
                hiv: clinicalData.hiv ? 0 : 1,
                hepatitis: clinicalData.hepatitis ? 0 : 1,
                highUricAcid: clinicalData.highUricAcid ? 0 : 1,
                cancerHistory: clinicalData.cancerHistory ? 0 : 1,
                leprosy: clinicalData.leprosy ? 0 : 1,
                medication: clinicalData.medication ? 0 : 1,
            };

            if (docSnap.exists()) {
                await updateDoc(docRef, dataToSave);
            } else {
                await setDoc(docRef, dataToSave);
            }
            setPopupMessage(t('successfully_updated'));
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
        } finally {
            setLoading(false);
            setShowPopup(true);
        }
        setLoading(false)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClinicalData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChange = (field, value) => {
        setClinicalData({ ...clinicalData, [field]: value });
    };

    if (loading) {
        return (
        <div className="loading-container-account">
            <div className="progress-bar-account"></div>
        </div>
        );
    }

    return (
        <div className="podopathologies-container">
            
            <div className="podopathologies-header">
                <div className="podopathologies-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
            </div>

            <RadioButtonGroup
                title={t("clinicalData.patient_an_alcoholic")}
                selectedValue={clinicalData.alcoholic}
                onChange={(value) => handleChange('alcoholic', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_allergies")}
                selectedValue={clinicalData.allergies}
                onChange={(value) => handleChange('allergies', value)}
            />

            {clinicalData.allergies && (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <input
                            type="text"
                            name="newAllergy"
                            value={clinicalData.newAllergy || ''}
                            onChange={(e) => setClinicalData(prevState => ({
                                ...prevState,
                                newAllergy: e.target.value
                            }))}
                            className="input-name"
                            placeholder={t("clinicalData.enter_new_allergy")}
                            style={{ flexGrow: 1, marginRight: '10px' }}
                        />
                        <button
                            onClick={async () => {
                                if (clinicalData.newAllergy) {
                                    const updatedAllergies = [...clinicalData.allergieswhat, clinicalData.newAllergy];
                                    const docRef = doc(db, `users/${userId}/patients/${patientId}/clinical_data/data`);
                            
                                    // Verificar se o documento existe
                                    const docSnap = await getDoc(docRef);
                            
                                    if (docSnap.exists()) {
                                        await updateDoc(docRef, {
                                            allergieswhat: updatedAllergies,
                                            allergies: true
                                        });
                                    } else {
                                        await setDoc(docRef, {
                                            allergieswhat: updatedAllergies,
                                            allergies: true
                                        });
                                    }
                            
                                    setClinicalData(prevState => ({
                                        ...prevState,
                                        allergieswhat: updatedAllergies,
                                        newAllergy: ''
                                    }));
                                }
                            }}                            
                            style={{ backgroundColor: '#00BFA5', color: 'white', padding: '5px 10px', border: 'none', cursor: 'pointer' }}
                        >
                            {t("clinicalData.add")}
                        </button>
                    </div>

                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {clinicalData.allergieswhat.map((allergy, index) => (
                            <div>
                                <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                                    <span>{allergy}</span>
                                    <button
                                        onClick={async () => {
                                            const updatedAllergies = clinicalData.allergieswhat.filter((_, i) => i !== index);
                                            const hasAllergies = updatedAllergies.length > 0;

                                            await updateDoc(doc(db, `users/${userId}/patients/${patientId}/clinical_data/data`), {
                                                allergieswhat: updatedAllergies,
                                                allergies: hasAllergies
                                            });

                                            setClinicalData(prevState => ({
                                                ...prevState,
                                                allergieswhat: updatedAllergies
                                            }));
                                        }}
                                        style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
                                    >
                                        🗑️
                                    </button>
                                </li>
                                <hr className="divider" />
                            </div>
                        ))}
                    </ul>
                </div>
            )}

            <RadioButtonGroup
                title={t("clinicalData.patient_have_heart_problems")}
                selectedValue={clinicalData.cardiov}
                onChange={(value) => handleChange('cardiov', value)}
            />

            {clinicalData.cardiov && (
                <div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={clinicalData.hypertension}
                                onChange={() =>
                                    handleChange('hypertension', !clinicalData.hypertension)
                                }
                                style={{ marginRight: '5px' }}
                            />
                            {t("clinicalData.hypertension")}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={clinicalData.circulatoryProblems}
                                onChange={() =>
                                    handleChange('circulatoryProblems', !clinicalData.circulatoryProblems)
                                }
                                style={{ marginRight: '5px' }}
                            />
                            {t("clinicalData.circulatory_problems")}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={clinicalData.heartDisease}
                                onChange={() =>
                                    handleChange('heartDisease', !clinicalData.heartDisease)
                                }
                                style={{ marginRight: '5px' }}
                            />
                            {t("clinicalData.heart_disease")}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={clinicalData.pacemaker}
                                onChange={() =>
                                    handleChange('pacemaker', !clinicalData.pacemaker)
                                }
                                style={{ marginRight: '5px' }}
                            />
                            {t("clinicalData.pacemaker")}
                        </label>
                    </div>
                </div>
            )}

            <RadioButtonGroup
                title={t("clinicalData.patient_have_diabetes")}
                selectedValue={clinicalData.diabetes}
                onChange={(value) => handleChange('diabetes', value)}
            />

            {clinicalData.diabetes && (
                <div>
                    <label>{t("clinicalData.diabetes_time")}</label>
                    <input 
                        type="text" 
                        name="diabetestime" 
                        value={clinicalData.diabetestime} 
                        onChange={handleInputChange} 
                        className="input-name" 
                    />
                    <label>{t("clinicalData.diabetes_type")}</label>
                    <input 
                        type="text" 
                        name="diabetestype" 
                        value={clinicalData.diabetestype} 
                        onChange={handleInputChange} 
                        className="input-name" 
                    />
                </div>
            )}

            <RadioButtonGroup
                title={t("clinicalData.patient_have_ophthalmological_problems")}
                selectedValue={clinicalData.ophtalm}
                onChange={(value) => handleChange('ophtalm', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_kidney_problems")}
                selectedValue={clinicalData.renal}
                onChange={(value) => handleChange('renal', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_smoker")}
                selectedValue={clinicalData.smoking}
                onChange={(value) => handleChange('smoking', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_dermatitis")}
                selectedValue={clinicalData.dermatitis}
                onChange={(value) => handleChange('dermatitis', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_psoriasis")}
                selectedValue={clinicalData.psoriasis}
                onChange={(value) => handleChange('psoriasis', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_hiv")}
                selectedValue={clinicalData.hiv}
                onChange={(value) => handleChange('hiv', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_hepatitis")}
                selectedValue={clinicalData.hepatitis}
                onChange={(value) => handleChange('hepatitis', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_high_uric_acid")}
                selectedValue={clinicalData.highUricAcid}
                onChange={(value) => handleChange('highUricAcid', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_cancer_history")}
                selectedValue={clinicalData.cancerHistory}
                onChange={(value) => handleChange('cancerHistory', value)}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_have_leprosy")}
                selectedValue={clinicalData.leprosy}
                onChange={(value) => handleChange('leprosy', value)}
            />

            <RadioButtonThree  
                title={t("clinicalData.pain_sensitivity")}
                titleOne={t("clinicalData.pain_sensitivity_high")}
                titleTwo={t("clinicalData.pain_sensitivity_tolerable")}
                titleThree={t("clinicalData.pain_sensitivity_low")}
                selectedButton={(value) => handleChange('painSensitivity', value)}
                index={clinicalData.painSensitivity}
            />

            <RadioButtonGroup
                title={t("clinicalData.patient_take_medication")}
                selectedValue={clinicalData.medication}
                onChange={(value) => handleChange('medication', value)}
            />

            {clinicalData.medication && (
                <div>
                    <label>
                        {t("clinicalData.list_medications")}
                        <textarea
                            value={clinicalData.medicationList}
                            onChange={(e) => handleChange('medicationList', e.target.value)}
                            rows="4"
                            style={{ width: '100%', marginTop: '5px' }}
                        />
                    </label>
                </div>
            )}

            <div style={{ marginTop: '15px' }}>
                <label>{t("clinicalData.other_clinical_problems")}</label>
                <textarea
                    name="otherclinicalproblems"
                    value={clinicalData.otherclinicalproblems || ''}
                    onChange={handleInputChange}
                    rows="4"
                    style={{ width: '100%', marginTop: '5px' }}
                />
            </div>

            <button onClick={handleSave} className="save-button" disabled={loading}>
                {t('update')}
            </button>
                    
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)} className="close-popup">{t('close')}</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ClinicalData;
