import React, { useState, useEffect } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import './PatientProfile.css';

const PatientProfile = ({ userId, patientId, onSectionChange }) => {
    
    const handleSectionClick = (section) => {
        if (onSectionChange) {
            onSectionChange('options');
        }
    };

    const db = getFirestore();
    const storage = getStorage();
    const { t } = useTranslation();
    
    const [patientData, setPatientData] = useState({
        name: '',
        birthday: '',
        phone: '',
        telegram: '',
        gender: '2',
        city: '',
        district: '',
        address: '',
        profession: '',
        url_image: ''
    });
    const [newImage, setNewImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchPatientData = async () => {
            const docRef = doc(db, `users/${userId}/patients/${patientId}`);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                
                if (data.birthday) {
                    const [day, month, year] = data.birthday.split('/'); // Separando dia, mês e ano
                    const formattedDate = `${year}-${month}-${day}`; // Reformatando para yyyy-MM-dd
                    setPatientData({ ...data, birthday: formattedDate });
                } else {
                    console.warn('A data de aniversário não está definida');
                    // Aqui você pode definir um valor padrão ou lidar com a ausência da data
                    setPatientData({ ...data, birthday: 'N/A' });
                }
            }
            setLoading(false)
        };
        fetchPatientData();
    }, [userId, patientId]);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setNewImage(e.target.files[0]);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPatientData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSaveProfile = async () => {
        setLoading(true);
        let imageUrl = patientData.url_image;
    
        // Validação do campo 'name'
        if (!patientData.name.trim()) {
            setPopupMessage(t('profile.nameRequired')); // Mensagem de erro
            setLoading(false);
            setShowPopup(true);
            return; // Não continua se o nome estiver vazio
        }
    
        try {
            // Verifica se há uma nova imagem e faz o upload
            if (newImage) {
                const imageRef = ref(storage, `users/${userId}/patients/${patientId}/profile/profile.jpg`);
                await uploadBytes(imageRef, newImage);
                imageUrl = await getDownloadURL(imageRef);
                // Atualiza o estado com a nova URL da imagem
                setPatientData(prevState => ({
                    ...prevState,
                    url_image: imageUrl // Atualiza a URL da imagem no estado
                }));
            }
    
            const [year, month, day] = patientData.birthday.split('-'); // Separando ano, mês e dia
            const formattedBirthday = `${day}/${month}/${year}`; // Reformatando para dd/MM/yyyy

            const patientRef = doc(db, `users/${userId}/patients/${patientId}`);
            await updateDoc(patientRef, {
                ...patientData,
                birthday: formattedBirthday, // Atualiza o campo de data com o formato desejado
                url_image: imageUrl
            });
            setPopupMessage(t('successfully_updated'));
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
        } finally {
            setLoading(false);
            setShowPopup(true);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    if (loading) {
      return (
        <div className="loading-container-account">
          <div className="progress-bar-account"></div>
        </div>
      );
    }

    return (
        <div className="profile">
            <div className="profile-container">
                <div className="profile-header">
                    <div className="profile-close-icon" onClick={handleSectionClick}>{`<- ${t('back')}`}</div>
                </div>
                {patientData.url_image !== "" ? (
                    <img 
                        src={patientData.url_image} 
                        alt={patientData.name} 
                        className="profile-image" 
                    />
                ) : (
                    <img 
                        src={require('../../images/logo.png')} 
                        alt={patientData.name} 
                        className="profile-image" 
                        style={{ objectFit: 'contain' }} 
                    />
                )}
                <input 
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange} 
                    className="image-upload" />
                
                <label>{t('profile.name')}</label>
                <input type="text" name="name" value={patientData.name} onChange={handleInputChange} className="input-name" />
                
                <label>{t('profile.birthDate')}</label>
                <input type="date" name="birthday" value={patientData.birthday} onChange={handleInputChange} className="input-birthday" />
                
                <label>{t('profile.phone')}</label>
                <input type="text" name="phone" value={patientData.phone} onChange={handleInputChange} className="input-phone" />
                
                <label>{t('profile.email')}</label>
                <input type="email" name="email" value={patientData.email} onChange={handleInputChange} className="input-email" />
                
                <label>{t('profile.telegram')}</label>
                <input type="text" name="telegram" value={patientData.telegram} onChange={handleInputChange} className="input-telegram" />
                
                <label>{t('profile.gender')}</label>
                <div className="gender-container">
                    <input type="radio" name="gender" value="1" checked={patientData.gender === '1'} onChange={handleInputChange} id="female" />
                    <label htmlFor="female">{t('profile.female')}</label>
                    <input type="radio" name="gender" value="2" checked={patientData.gender !== '1'} onChange={handleInputChange} id="male" />
                    <label htmlFor="male">{t('profile.male')}</label>
                </div>
                
                <label>{t('profile.city')}</label>
                <input type="text" name="city" value={patientData.city} onChange={handleInputChange} className="input-city" />
                
                <label>{t('profile.district')}</label>
                <input type="text" name="district" value={patientData.district} onChange={handleInputChange} className="input-district" />
                
                <label>{t('profile.address')}</label>
                <input type="text" name="address" value={patientData.address} onChange={handleInputChange} className="input-address" />
                
                <label>{t('profile.profession')}</label>
                <input type="text" name="profession" value={patientData.profession} onChange={handleInputChange} className="input-profession" />
                
                <button onClick={handleSaveProfile} className="save-button" disabled={loading}>
                    {loading ? t('updating') : t('update')}
                </button>
                
                {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <p>{popupMessage}</p>
                            <button onClick={closePopup} className="close-popup">{t('close')}</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PatientProfile;
