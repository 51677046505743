import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import Slider from 'react-slick';
import './LoginPage.css';
import i18n from '../i18n/i18n';
import { Link } from 'react-router-dom';

import imageArA from '../images/locales/ar/imageA.png';
import imageBnA from '../images/locales/bn/imageA.png';
import imageDeA from '../images/locales/de/imageA.png';
import imageEnA from '../images/locales/en/imageA.png';
import imageEsA from '../images/locales/es/imageA.png';
import imageFrA from '../images/locales/fr/imageA.png';
import imageIdA from '../images/locales/id/imageA.png';
import imageItA from '../images/locales/it/imageA.png';
import imageJaA from '../images/locales/ja/imageA.png';
//import imageKoA from '../images/locales/ko/imageA.png';
import imagePtBrA from '../images/locales/pt-br/imageA.png';
import imageRuA from '../images/locales/ru/imageA.png';
import imageZhCnA from '../images/locales/zh-cn/imageA.png';

const LoginPage = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showRegisterPopup, setShowRegisterPopup] = useState(false);
    const [showLostAccountPopup, setShowLostAccountPopup] = useState(false);
    const [registerName, setRegisterName] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [lostAccountEmail, setLostAccountEmail] = useState('');
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const [loading, setLoading] = useState(false);

    const currentLanguage = i18n.language;

    console.log(currentLanguage)

    const imagesMap = {
        'ar': [imageArA],
        'bn': [imageBnA],
        'de': [imageDeA],
        'en': [imageEnA],
        'es': [imageEsA],
        'fr': [imageFrA],
        'id': [imageIdA],
        'it': [imageItA],
        'ja': [imageJaA],
        //'ko': [imageKoA],
        'pt-BR': [imagePtBrA],
        'ru': [imageRuA],
        'zh-CN': [imageZhCnA],
    };

    const images = imagesMap[currentLanguage] || [imagePtBrA];

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setLoading(true)
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
            setShowPopup(true)
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (agreeTerms) {
            if (registerName.trim().length > 3) {
                if (registerEmail === confirmEmail) {
                    if (registerPassword === confirmPassword) {

                        let timestamp = Date.now()
                        let sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
                        let sevenDaysPremium = timestamp + sevenDaysInMilliseconds;

                        const authUser = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword);
    
                        await setDoc(doc(db, 'users', authUser.user.uid), {
                            name: registerName.toLowerCase(),
                            url_image: "",
                            premiumTime: sevenDaysPremium
                        });
                        
                        setPopupMessage(`${t('registered_successfully')}`);
                        setShowPopup(true)
                        setRegisterName('');
                        setRegisterEmail('');
                        setConfirmEmail('');
                        setRegisterPassword('');
                        setConfirmPassword('');
                        setShowRegisterPopup(false);
                    } else {
                        setPopupMessage(`${t('password_not_match')}`);
                        setShowPopup(true)
                    }
                } else {
                    setPopupMessage(`${t('email_not_match')}`);
                    setShowPopup(true)
                }
            } else {
                setPopupMessage(`${t('invalid_name')}`);
                setShowPopup(true)
            }
        } else {
            setPopupMessage(`${t('must_agree_terms')}`);
            setShowPopup(true)
        }
    };

    const handleLostAccount = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, lostAccountEmail)
            .then(() => {
                setPopupMessage(`${t('reset_email_sent')}`);
                setShowPopup(true)
                setLostAccountEmail("")
                setShowLostAccountPopup(false);
            });
        } catch (error) {
            setPopupMessage(`${t('error')}: ${error.message}`);
            setShowPopup(true)
        }
    }; 

    const closePopup = () => {
        setShowPopup(false);
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false
    };

    if (loading) {
        return (
            <div className="loading-container-login">
                <div className="progress-bar-login"></div>
            </div>
        );
    }

    return (
        <div className="login-page">
            <nav className="navbar">
                <div className="navbar-logo">Podana</div>
                <form className="login-form" onSubmit={handleLogin}>
                    <input
                        type="email"
                        name="email"
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder={t('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">{t('login')}</button>
                    <button type="button" onClick={() => setShowRegisterPopup(true)}>
                        {t('register')}
                    </button>
                    <button type="button" onClick={() => setShowLostAccountPopup(true)}>
                        {t('lost_account')}
                    </button>
                </form>
            </nav>
            <div className="content">
                <Slider {...sliderSettings} className="carousel">
                    {images.map((src, index) => (
                        <div key={index}>
                            <img src={src} alt={`Slide ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>

            {showRegisterPopup && (
                <div className="register-popup">
                    <div className="popup-content">
                        <button className="close-popup" onClick={() => setShowRegisterPopup(false)}>X</button>
                        <form className="register-form" onSubmit={handleRegister}>
                            <input
                                type="text"
                                placeholder={t('name')}
                                value={registerName}
                                onChange={(e) => setRegisterName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder={t('email')}
                                value={registerEmail}
                                onChange={(e) => setRegisterEmail(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder={t('confirmEmail')}
                                value={confirmEmail}
                                onChange={(e) => setConfirmEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder={t('password')}
                                value={registerPassword}
                                onChange={(e) => setRegisterPassword(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder={t('confirmPassword')}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={agreeTerms}
                                    onChange={() => setAgreeTerms(!agreeTerms)}
                                    />
                                <span>
                                    {t('agreeTerms')} <Link to="/terms-of-service">{t('terms.title')}</Link> {t('and')} <Link to="/privacy-policy">{t('privPol')}</Link>
                                </span>
                            </label>
                            <button type="submit">{t('register')}</button>
                        </form>
                    </div>
                </div>
            )}

            {showLostAccountPopup && (
                <div className="register-popup">
                    <div className="popup-content">
                        <button className="close-popup" onClick={() => setShowLostAccountPopup(false)}>X</button>
                        <form className="register-form" onSubmit={handleLostAccount}>
                        <input
                            type="email"
                            placeholder={t('email')}
                            value={lostAccountEmail}
                            onChange={(e) => setLostAccountEmail(e.target.value)}
                            required
                        />
                        <button type="submit">{t('send_reset_email')}</button>
                        </form>
                    </div>
                </div>
            )}


                
            {showPopup && (
                <div className="popup-message">
                    <div className="popup-content-message">
                        <p>{popupMessage}</p>
                        <button onClick={closePopup} className="close-popup-message">{t('close')}</button>
                    </div>
                </div>
            )}

            
        </div>
    );
};

export default LoginPage;