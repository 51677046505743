import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './TermsOfService.css';

const TermsOfService = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="terms-of-service-terms">
            <div className="top-bar-terms">
                <button className="back-button-terms" onClick={() => navigate(-1)}>
                    &#8592;
                </button>
                <h1 className="app-title-terms">Podana</h1>
            </div>
            <div className="content-terms">
                <h2>{t('terms.title')}</h2>
                <p>{t('terms.description')}</p>
                <h3>{t('terms.userRegistrationTitle')}</h3>
                <p>{t('terms.userRegistration')}</p>
                <h3>{t('terms.subscriptionTitle')}</h3>
                <p>{t('terms.subscription')}</p>
                <h3>{t('terms.patientDataTitle')}</h3>
                <p>{t('terms.patientData')}</p>
                <h3>{t('terms.changesTitle')}</h3>
                <p>{t('terms.changes')}</p>
                <h3>{t('terms.limitationOfLiabilityTitle')}</h3>
                <p>{t('terms.limitationOfLiability')}</p>
                <h3>{t('terms.jurisdictionTitle')}</h3>
                <p>{t('terms.jurisdiction')}</p>
            </div>
        </div>
    );
};

export default TermsOfService;
