import { useEffect, useState } from 'react';
import './HandDots.css';
import { useTranslation } from 'react-i18next';
import { 
    collection, 
    doc, 
    addDoc, 
    deleteDoc, 
    onSnapshot, 
    getFirestore 
} from 'firebase/firestore';

const db = getFirestore();

function RightHandPalmar({ userId, patientId }) {
    const [isColor, setIsColor] = useState(null);
    const [dots, setDots] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (userId && patientId) {
            const dorsalCollection = collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'hands', 'rightHand', 'palmar'
            );

            const unsubscribe = onSnapshot(dorsalCollection, (snapshot) => {
                const ballsD = snapshot.docs.map(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    return data;
                });
                setDots(ballsD);
                setLoading(false)
            });

            return () => unsubscribe();
        }
    }, [userId, patientId]);

    function dot(event) {
        if (isColor) {
            let x = event.nativeEvent.offsetX;
            let y = event.nativeEvent.offsetY;

            const dorsalCollection = collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'hands', 'rightHand', 'palmar'
            );

            addDoc(dorsalCollection, { isColor, x, y })
                .then(docRef => {
                    setDots([...dots, { isColor, x, y, id: docRef.id }]);
                })
                .catch((error) => {
                    console.error('Erro ao adicionar documento: ', error);
                });
        } else {
            alert(t('hands.selProblem'));
        }
    }

    function removeBall(ballId) {
        const ballDocRef = doc(
            collection(
                doc(collection(db, 'users'), userId),
                'patients', patientId, 'hands', 'rightHand', 'palmar'
            ), 
            ballId
        );

        deleteDoc(ballDocRef)
            .then(() => {
                setDots(dots.filter((ball) => ball.id !== ballId));
            })
            .catch((error) => {
                console.error('Erro ao remover documento: ', error);
            });
    }

    if (loading) {
        return (
        <div className="loading-container-account">
            <div className="progress-bar-account"></div>
        </div>
        );
    }

    return (
        <div className='patientLeftHand'>
            <ul className="symptomList">
                <li style={{ backgroundColor: 'red' }} onClick={() => setIsColor('red')}>
                    {t('hands.wart')}
                </li>
                <li style={{ backgroundColor: 'blue' }} onClick={() => setIsColor('blue')}>
                    {t('hands.keratosis')}
                </li>
                <li style={{ backgroundColor: 'orange' }} onClick={() => setIsColor('orange')}>
                    {t('hands.onychocryptosis')}
                </li>
                <li style={{ backgroundColor: 'deeppink' }} onClick={() => setIsColor('deeppink')}>
                    {t('hands.onychomycosis')}
                </li>
            </ul>
            <ul className="symptomList">
                <li style={{ backgroundColor: 'purple' }} onClick={() => setIsColor('purple')}>
                    {t('hands.pyogenicGranuloma')}
                </li>
                <li style={{ backgroundColor: 'brown' }} onClick={() => setIsColor('brown')}>
                    {t('hands.onycholysis')}
                </li>
                <li style={{ backgroundColor: 'darkgreen' }} onClick={() => setIsColor('darkgreen')}>
                    {t('hands.dehydrosis')}
                </li>
                <li style={{ backgroundColor: 'black' }} onClick={() => setIsColor('black')}>
                    {t('hands.psoriasis')}
                </li>
            </ul>

            <div className='imgAndDots'>
                <img
                    src={require('../../../images/mao-dir-baixo.png')}
                    onClick={dot}
                    alt={t('hands.leftHand')}
                />
                {dots.map((dotD, i) => (
                    <div
                        key={i}
                        className="dot"
                        style={{
                            top: dotD.y - 7,
                            left: dotD.x - 6,
                            position: 'absolute',
                            backgroundColor: dotD.isColor
                        }}
                        onClick={() => removeBall(dotD.id)}
                    />
                ))}
            </div>
        </div>
    );
}

export default RightHandPalmar;