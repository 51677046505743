import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDoc, getDocs, query, where, doc, addDoc, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getApp } from "firebase/app";
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import './Premium.css'; // Importa o arquivo CSS

const db = getFirestore();
const auth = getAuth();
const stripeKey = 'pk_live_51NVI3TFO4yyekLj6ujpsoPZDVgCO2pgv4L9mABqoyVZsaQywz8ncxIF43FV4WcmAXi0Wp1HybvSkU6f3lVXAD8cC0034NYyEyE'
const url = 'https://podana.net/'

const Premium = ({ isMobileScreen }) => {
    const { t } = useTranslation();
    const [premiumTime, setPremiumTime] = useState(null);
    const [isPremium, setIsPremium] = useState(false);
    const [userId, setUserId] = useState(null);

    const [prods, setProds] = useState([])
    const [subClicked, setSubClicked] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setUserId(user.uid);
        }
        });
        return () => unsubscribe();
    }, []);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString(); // Formata a data de acordo com a região do usuário
    };

    useEffect(() => {
        const fetchPremiumTime = async () => {
            if (userId) {
                const db = getFirestore();
                const userDocRef = doc(db, 'users', userId); // Pega o documento do Firestore para o usuário

                try {
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const premiumTimestamp = userData.premiumTime;

                    if (premiumTimestamp > Date.now()) {
                    setIsPremium(true);
                    setPremiumTime(premiumTimestamp);
                    }
                    console.log(`premiumTime: ${premiumTimestamp}`);
                    loadProducts()
                } else {
                    console.log("No such document!");
                }
                } catch (error) {
                console.error("Error fetching premium time:", error);
                } 
            }
        };

        fetchPremiumTime();
    }, [userId]);

    async function loadProducts() {
        try {
            const productsQuery = query(collection(db, "products"), where("active", "==", true));
            const snapshot = await getDocs(productsQuery);
    
            const products = {};
            for (const prodDoc of snapshot.docs) {
                products[prodDoc.id] = prodDoc.data();
    
                const pricesCollection = collection(prodDoc.ref, 'prices');
                const priceSnap = await getDocs(pricesCollection);
                
                priceSnap.forEach(priceDoc => {
                    products[prodDoc.id].prices = {
                        priceId: priceDoc.id,
                        priceData: priceDoc.data()
                    };
                });
            }
            setProds(products);
            setLoading(false);
            console.log(products);
            console.log(prods);
        } catch (error) {
            console.error("Error loading products: ", error);
        }
    }
    
    const checkOut = async (priceId) => {
        try {
            setSubClicked(true);
            
            const customerRef = doc(db, 'customers', auth.currentUser.uid);
            const checkoutSessionsRef = collection(customerRef, 'checkout_sessions');
    
            const docRef = await addDoc(checkoutSessionsRef, {
                price: priceId,
                success_url: url,
                cancel_url: url,
                mode: "payment"
            });
    
            onSnapshot(docRef, async (snap) => {
                const { error, sessionId } = snap.data();
    
                if (error) {
                    alert(error.message);
                    setSubClicked(false);
                }
    
                if (sessionId) {
                    const stripe = await loadStripe(stripeKey);
                    stripe.redirectToCheckout({ sessionId });
                }
            });
        } catch (error) {
            console.error("Error during checkout: ", error);
        }
    }

    const handleLogout = () => {
        auth.signOut().then(() => {
            window.location.href = '/';
        }).catch((error) => {
            console.error(t('error'), error);
        });
    };

    if (loading) {
        return (
            <div className="loading-container-premium">
                <div className="progress-bar-premium"></div>
            </div>
        );
    }

    return (
        <div className="premium-container">
            <div className="premium-box">
                {isPremium ? (
                <>
                    <p>{t('premiumView.youHavePremiumAccessUntil')} {formatDate(premiumTime)}</p>
                    <hr className="divider" />
                    <p>{t('premiumView.youAreAdFree')}</p>
                    <p>{t('premiumView.youHaveAccessHandsFeetMonitorings')}</p>
                    <p>{t('premiumView.youHaveAccessToWebVersion')}</p>
                </>
                ) : (
                <>
                    <p>{t('premiumView.youDontHavePremiumTime')}</p>
                    <hr className="divider" />
                    <p>{t('premiumView.youCanPurchasePremiumFor')}</p>
                    <p>{t('premiumView.adFree')}</p>
                    <p>{t('premiumView.accessHandsFeetMonitorings')}</p>
                    <p>{t('premiumView.youWillHaveAccessToWebVersion')}</p>
                </>
                )}

                <div className="products">
                    {
                        Object.entries(prods).map(([productId, productData]) => (
                            <div key={productId} className='paymentProduct'>
                                <button 
                                    className='btnPayment'
                                    disabled={subClicked}
                                    onClick={() => checkOut(productData.prices.priceId)}>
                                    {subClicked ? t('loading') : `${t('buy_now')} - ${productData.name}`}
                                </button>
                            </div>
                        ))
                    }
                </div>

                {isMobileScreen &&

                    <div>
                        <h4 style={{ marginTop: '20px' }}>{t('premiumNote')}</h4>
                        <button 
                            onClick={handleLogout}
                            style={{ 
                                backgroundColor: '#00BFA5',
                                width: '100%',
                                color: 'white',
                                height: '50px',
                                fontWeight: 'bold',
                                fontSize: '16px'
                            }}>
                            {t('logout')}
                        </button>
                    </div>

                }
            </div>
        </div>
    );
};

export default Premium;