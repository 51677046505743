import React from 'react';
import { useTranslation } from 'react-i18next';

const RadioButtonGroup = ({ title, selectedValue, onChange }) => {

  const { t } = useTranslation();

  return (
    <div className="radio-group">
      <p>{title}</p>
      <div className="radio-options">
        <label>
          <input
            type="radio"
            value={true}
            checked={selectedValue === true || selectedValue === 0}
            onChange={() => onChange(true)}
          />
          {t('yes')}
        </label>
        <label>
          <input
            type="radio"
            value={false}
            checked={selectedValue === false || selectedValue === 1}
            onChange={() => onChange(false)}
          />
          {t('no')}
        </label>
      </div>
      <hr className="divider" />
    </div>
  );
};

export default RadioButtonGroup;
