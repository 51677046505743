import React, { useState } from 'react';
import './Feet.css';
import { useTranslation } from 'react-i18next';
import LeftFootDorsal from './Feet/LeftFootDorsal';
import LeftFootPlantar from './Feet/LeftFootPlantar';
import RightFootDorsal from './Feet/RightFootDorsal';
import RightFootPlantar from './Feet/RightFootPlantar';
import FeetInfo from './Feet/FeetInfo';

const Feet = ({ userId, patientId, onSectionChange }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(null);

    return (
        <div className='feet-layout'>
            <div className="feet-header">
                <div className="feet-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
            </div>

            <div className="tab-buttons">
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('leftFootDorsal')}>
                    {t('feetTabs.leftFootDorsal')}
                </button>
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('leftFootPlantar')}>
                    {t('feetTabs.leftFootPlantar')}
                </button>
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('feetInfo')}>
                    {t('feetTabs.feetInfo')}
                </button>
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('rightFootDorsal')}>
                    {t('feetTabs.rightFootDorsal')}
                </button>
                <button onClick={() => setActiveTab('rightFootPlantar')}>
                    {t('feetTabs.rightFootPlantar')}
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'leftFootDorsal' && <LeftFootDorsal userId={userId} patientId={patientId} />}
                {activeTab === 'leftFootPlantar' && <LeftFootPlantar userId={userId} patientId={patientId} />}
                {activeTab === 'feetInfo' && <FeetInfo userId={userId} patientId={patientId} />}
                {activeTab === 'rightFootDorsal' && <RightFootDorsal userId={userId} patientId={patientId} />}
                {activeTab === 'rightFootPlantar' && <RightFootPlantar userId={userId} patientId={patientId} />}
            </div>
        </div>
    );
};

export default Feet;