import React from 'react';
import './CustomRadioButtons.css'; // Para os estilos dos botões de rádio

const RadioButtonThree = ({ title, titleOne, titleTwo, titleThree, selectedButton, index }) => {
    const handleChange = (e) => {
        selectedButton(Number(e.target.value)); // Converte o valor para número ao passar para o handleChange
    };

    return (
        <div className="radio-group">
            <label>{title}</label>
            <div className="radio-options">
                <label>
                    <input 
                        type="radio" 
                        value="0" 
                        checked={index === 0} 
                        onChange={handleChange} 
                    />
                    {titleOne}
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="1" 
                        checked={index === 1} 
                        onChange={handleChange} 
                    />
                    {titleTwo}
                </label>
                <label>
                    <input 
                        type="radio" 
                        value="2" 
                        checked={index === 2} 
                        onChange={handleChange} 
                    />
                    {titleThree}
                </label>
            </div>
            <hr className="divider" />
        </div>
    );
};

export default RadioButtonThree;
