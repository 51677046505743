import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore'; 
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyB_Z9X8vcDEjegY7BZJtKI6jq9w_pet8hw",
  authDomain: "podana-7e6d6.firebaseapp.com",
  projectId: "podana-7e6d6",
  storageBucket: "podana-7e6d6.appspot.com",
  messagingSenderId: "852027548602",
  appId: "1:852027548602:web:4b6c29d9f435fe655ef383",
  measurementId: "G-LQC9FPT57W"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const messaging = getMessaging(app);
export const analytics = getAnalytics(app);

