import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  doc,
  addDoc,
  deleteDoc,
  onSnapshot,
  updateDoc
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import './Monitorings.css';

const Monitorings = ({ userId, patientId, onSectionChange }) => {
    const { t } = useTranslation();
    const [monitorings, setMonitorings] = useState([]);
    const [selectedMonitoring, setSelectedMonitoring] = useState(null);
    const [deleteMonitoringId, setDeleteMonitoringId] = useState(null);
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [newMonitoringData, setNewMonitoringData] = useState("");
    const [newDate, setNewDate] = useState("");
    const [newImage, setNewImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [monitoringSize, setMonitoringSize] = useState(0)
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const db = getFirestore();
    const storage = getStorage();

    useEffect(() => {
        const monitoringsRef = collection(db, `users/${userId}/patients/${patientId}/monitoring`);
        const q = query(monitoringsRef, orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const data = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMonitorings(data);
            setMonitoringSize(snapshot.size)
            setLoading(false);
        });

        return () => unsubscribe();
    }, [db, userId, patientId]);

    const handleDelete = async () => {
        const monitoringDoc = doc(db, `users/${userId}/patients/${patientId}/monitoring`, deleteMonitoringId);
        const monitoringData = monitorings.find(m => m.id === deleteMonitoringId);
        if (monitoringData && monitoringData.url_image) {
            const imageRef = ref(storage, monitoringData.url_image);
            await deleteObject(imageRef);
        }
        await deleteDoc(monitoringDoc);
        setDeleteMonitoringId(null);
    };

    const handleAddMonitoring = async () => {
        if (newMonitoringData && newDate && newImage) {
            setLoading(true)

            const docRef = await addDoc(collection(db, `users/${userId}/patients/${patientId}/monitoring`), {
                monitoringData: newMonitoringData,
                date: newDate,
                timestamp: Date.now(),
            });

            const monitoringDocRef = doc(db, `users/${userId}/patients/${patientId}/monitoring`, docRef.id);

            const imageRef = ref(storage, `users/${userId}/patients/${patientId}/monitoring/${docRef.id}.jpg`); // Nome da imagem
            await uploadBytes(imageRef, newImage)
            .then(() => {
                setLoading(false)
            });
            const imageUrl = await getDownloadURL(imageRef);

            await updateDoc(monitoringDocRef, {
                url_image: imageUrl,
            });

            setNewMonitoringData("");
            setNewDate("");
            setNewImage(null);
            setIsAddPopupOpen(false);
        }
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    };

    const handleAddPopUp = () => {
        console.log(monitoringSize)
        if(monitoringSize <= 15) {
            setIsAddPopupOpen(true)
        } else {
            setPopupMessage(t('The limit of 15 monitorings per patient has been exceeded!'))
            setShowPopup(true)
        }
    }

    if (loading) {
        return (
          <div className="loading-container-account">
            <div className="progress-bar-account"></div>
          </div>
        );
    }

    return (
        <div className="monitorings-container">
            <div className="monitorings-header">
                <div className="monitorings-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
                <button className="monitorings-add-btn" onClick={handleAddPopUp}>
                    {t('addMonitoring')}
                </button>
            </div>

            <div className="monitorings-grid">
                {monitorings.map(monitoring => (
                    <div className="monitorings-portrait" key={monitoring.id}>
                        <div className="monitorings-delete-icon" onClick={() => setDeleteMonitoringId(monitoring.id)}>🗑️</div>
                        <img
                            src={monitoring.url_image}
                            alt="Monitoring"
                            style={{ width: '150px', height: '200px', objectFit: "cover" }}
                            onClick={() => setSelectedMonitoring(monitoring)}
                        />
                        <div className="monitorings-footer">
                            <div className="monitorings-date">{monitoring.date}</div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Popup for monitoring details */}
            {selectedMonitoring && (
                <div className="monitorings-popup-background" onClick={() => setSelectedMonitoring(null)}>
                    <div className="monitorings-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="monitorings-popup-close" onClick={() => setSelectedMonitoring(null)}>X</div>
                        <img
                            src={selectedMonitoring.url_image}
                            alt="Monitoring"
                            style={{ width: '100%', height: 'auto' }} // Largura 100% da imagem
                        />
                        <div className="monitorings-popup-text">
                            {selectedMonitoring.monitoringData}
                        </div>
                    </div>
                </div>
            )}

            {/* Delete confirmation popup */}
            {deleteMonitoringId && (
                <div className="monitorings-popup-background" onClick={() => setDeleteMonitoringId(null)}>
                    <div className="monitorings-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="monitorings-popup-close" onClick={() => setDeleteMonitoringId(null)}>X</div>
                        <div className="monitorings-popup-text">
                            {t('deleteMessageMonitorings')}
                        </div>
                        <div className="monitorings-popup-actions">
                            <button className="monitorings-btn monitorings-btn-cancel" onClick={() => setDeleteMonitoringId(null)}>
                                {t('cancel')}
                            </button>
                            <button className="monitorings-btn monitorings-btn-delete" onClick={handleDelete}>
                                {t('delete')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add new monitoring popup */}
            {isAddPopupOpen && (
                <div className="monitorings-popup-background" onClick={() => setIsAddPopupOpen(false)}>
                    <div className="monitorings-popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="monitorings-popup-close" onClick={() => setIsAddPopupOpen(false)}>X</div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                        <textarea
                            className="monitorings-new-data"
                            placeholder={t('newNote')}
                            value={newMonitoringData}
                            onChange={(e) => setNewMonitoringData(e.target.value)}
                        />
                        <input
                            className="monitorings-new-date"
                            type="date"
                            value={newDate}
                            onChange={(e) => setNewDate(e.target.value)}
                        />
                        <button className="monitorings-btn monitorings-btn-add" onClick={handleAddMonitoring}>
                            {t('add')}
                        </button>
                    </div>
                </div>
            )}
                
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={() => setShowPopup(false)} className="close-popup">{t('profile.close')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Monitorings;
