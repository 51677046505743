import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonGroup from './RadioButtonGroup';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './PodopathologiesClinical.css';  // Para o CSS customizado

const Podopathologies = ({ userId, patientId, onSectionChange }) => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true)
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const [podopathologies, setPodopathologies] = useState({
    anhidrosis: false,
    bromhidrosis: false,
    bunion: false,
    cavus: false,
    cn: false,
    crack: false,
    cyanotic: false,
    driedup: false,
    flat: false,
    hyperhi: false,
    keratosis: false,
    oily: false,
    onychocryptosis: false,
    onychomycosis: false,
    plantar: false,
    reflexes: false,
    slim: false,
    sn: false,
    swollen: false,
    varus: false,
    wart: false,
  });

  const db = getFirestore();

  useEffect(() => {
    
    const fetchData = async () => {
      const docRef = doc(db, `users/${userId}/patients/${patientId}/podopathologies/data`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPodopathologies(docSnap.data());
      }
      setLoading(false)
    };

    fetchData();
  }, [userId, patientId, db]);

  const handleSave = async () => {
    setLoading(true)
    
    try {
      
      const docRef = doc(db, `users/${userId}/patients/${patientId}/podopathologies/data`);

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, podopathologies);
      } else {
        await setDoc(docRef, podopathologies);
      }
      setPopupMessage(t('successfully_updated'));
    } catch (error) {
        setPopupMessage(`${t('error')}: ${error.message}`);
    } finally {
      setLoading(false);
      setShowPopup(true);
    }
    setLoading(false)
  };

  const handleChange = (field, value) => {
    setPodopathologies({ ...podopathologies, [field]: value });
  };

  if (loading) {
    return (
      <div className="loading-container-account">
        <div className="progress-bar-account"></div>
      </div>
    );
  }

  return (
    <div className="podopathologies-container">

        <div className="podopathologies-header">
            <div className="podopathologies-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
        </div>

        <RadioButtonGroup
            title={t("podopathologies.anhidrosis")}
            selectedValue={podopathologies.anhidrosis}
            onChange={(value) => handleChange('anhidrosis', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.bromhidrosis")}
            selectedValue={podopathologies.bromhidrosis}
            onChange={(value) => handleChange('bromhidrosis', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.bunion")}
            selectedValue={podopathologies.bunion}
            onChange={(value) => handleChange('bunion', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.cavus")}
            selectedValue={podopathologies.cavus}
            onChange={(value) => handleChange('cavus', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.cn")}
            selectedValue={podopathologies.cn}
            onChange={(value) => handleChange('cn', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.crack")}
            selectedValue={podopathologies.crack}
            onChange={(value) => handleChange('crack', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.cyanotic")}
            selectedValue={podopathologies.cyanotic}
            onChange={(value) => handleChange('cyanotic', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.driedup")}
            selectedValue={podopathologies.driedup}
            onChange={(value) => handleChange('driedup', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.flat")}
            selectedValue={podopathologies.flat}
            onChange={(value) => handleChange('flat', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.hyperhi")}
            selectedValue={podopathologies.hyperhi}
            onChange={(value) => handleChange('hyperhi', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.keratosis")}
            selectedValue={podopathologies.keratosis}
            onChange={(value) => handleChange('keratosis', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.oily")}
            selectedValue={podopathologies.oily}
            onChange={(value) => handleChange('oily', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.onychocryptosis")}
            selectedValue={podopathologies.onychocryptosis}
            onChange={(value) => handleChange('onychocryptosis', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.onychomycosis")}
            selectedValue={podopathologies.onychomycosis}
            onChange={(value) => handleChange('onychomycosis', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.plantar")}
            selectedValue={podopathologies.plantar}
            onChange={(value) => handleChange('plantar', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.reflexes")}
            selectedValue={podopathologies.reflexes}
            onChange={(value) => handleChange('reflexes', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.slim")}
            selectedValue={podopathologies.slim}
            onChange={(value) => handleChange('slim', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.sn")}
            selectedValue={podopathologies.sn}
            onChange={(value) => handleChange('sn', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.swollen")}
            selectedValue={podopathologies.swollen}
            onChange={(value) => handleChange('swollen', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.varus")}
            selectedValue={podopathologies.varus}
            onChange={(value) => handleChange('varus', value)}
        />

        <RadioButtonGroup
            title={t("podopathologies.wart")}
            selectedValue={podopathologies.wart}
            onChange={(value) => handleChange('wart', value)}
        />

        <button onClick={handleSave} className="save-button" disabled={loading}>
            {t('update')}
        </button>
                
        {showPopup && (
            <div className="popup">
                <div className="popup-content">
                    <p>{popupMessage}</p>
                    <button onClick={() => setShowPopup(false)} className="close-popup">{t('close')}</button>
                </div>
            </div>
        )}

    </div>
  );
};

export default Podopathologies;
