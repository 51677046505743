import React, { useState } from 'react';
import './Hands.css';
import { useTranslation } from 'react-i18next';
import LeftHandDorsal from './Hands/LeftHandDorsal';
import LeftHandPalmar from './Hands/LeftHandPalmar';
import RightHandDorsal from './Hands/RightHandDorsal';
import RightHandPalmar from './Hands/RightHandPalmar';

const Hands = ({ userId, patientId, onSectionChange }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(null);

    return (
        <div className='hands-layout'>
            <div className="hands-header">
                <div className="hands-close-icon" onClick={() => onSectionChange('options')}>{`<- ${t('back')}`}</div>
            </div>

            <div className="tab-buttons">
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('leftHandDorsal')}>
                    {t('handTabs.leftHandDorsal')}
                </button>
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('leftHandPalmar')}>
                    {t('handTabs.leftHandPalmar')}
                </button>
                <button style={{ marginRight: '5px' }} onClick={() => setActiveTab('rightHandDorsal')}>
                    {t('handTabs.rightHandDorsal')}
                </button>
                <button onClick={() => setActiveTab('rightHandPalmar')}>
                    {t('handTabs.rightHandPalmar')}
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'leftHandDorsal' && <LeftHandDorsal userId={userId} patientId={patientId} />}
                {activeTab === 'leftHandPalmar' && <LeftHandPalmar userId={userId} patientId={patientId} />}
                {activeTab === 'rightHandDorsal' && <RightHandDorsal userId={userId} patientId={patientId} />}
                {activeTab === 'rightHandPalmar' && <RightHandPalmar userId={userId} patientId={patientId} />}
            </div>
        </div>
    );
};

export default Hands;