import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useTranslation } from 'react-i18next';
import { httpsCallable, getFunctions } from 'firebase/functions';
import './AccountDetails.css';

const AccountDetails = () => {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState({ name: '', url_image: '' });
  const [newImage, setNewImage] = useState(null);
  const [newName, setNewName] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordEmail, setPasswordEmail] = useState('');
  const [passwordPassword, setPasswordPassword] = useState('');
  const [isEmailUpdating, setIsEmailUpdating] = useState(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false);

  const [deletePassword, setDeletePassword] = useState('');
  const [isDeleteChecked, setIsDeleteChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();
  const user = auth.currentUser;
  const functions = getFunctions();

  useEffect(() => {
    if (user) {
      fetchUserDetails(user.uid);
    }
  }, [user]);

  const fetchUserDetails = async (userId) => {
    try {
      const userDoc = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userDoc);
      if (userSnapshot.exists()) {
        setUserDetails(userSnapshot.data());
        setNewName(userSnapshot.data().name || '');
      }
    } catch (error) {
      console.error(t('fetchingUserDetails'), error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChangeAccount = (e) => {
    if (e.target.files[0]) {
      setNewImage(e.target.files[0]);
    }
  };

  const uploadImageAccount = async (userId, imageFile) => {
    try {
      const imageRef = ref(storage, `users/${userId}/profile/profile.jpg`);
      await uploadBytes(imageRef, imageFile);
      return await getDownloadURL(imageRef);
    } catch (error) {
      setPopupMessage(`${t('error')}: ${error}`);
      setShowPopup(true)
      return null;
    }
  };

  const handleUpdateAccount = async () => {
    if (newName.length >= 3) {
      setIsUpdating(true);
      setLoading(true);
      try {
        const userDoc = doc(db, 'users', user.uid);
        const updatedData = { name: newName };

        if (newImage) {
          const imageUrl = await uploadImageAccount(user.uid, newImage);
          if (imageUrl) {
            updatedData.url_image = imageUrl;
          }
        }

        await updateDoc(userDoc, updatedData);
        setPopupMessage(t('successfully_updated'));
        fetchUserDetails(user.uid);
      } catch (error) {
        setPopupMessage(`${t('error')}: ${error}`);
      } finally {
        setIsUpdating(false);
        setLoading(false);
        setShowPopup(true)
      }
    }
  };

  const handleEmailUpdateAccount = async () => {
    if (newEmail === confirmEmail && passwordEmail) {
      setIsEmailUpdating(true);
      setLoading(true);
      try {
        const userCredential = await signInWithEmailAndPassword(auth, user.email, passwordEmail);
        await updateEmail(userCredential.user, newEmail);
        setPopupMessage(t('successfully_updated'));
        setNewEmail('');
        setConfirmEmail('');
        setPasswordEmail('');
      } catch (error) {
        setPopupMessage(`${t('error')}: ${error}`);
      } finally {
        setIsEmailUpdating(false);
        setLoading(false);
        setShowPopup(true)
      }
    }
  };

  const handlePasswordUpdateAccount = async () => {
    if (newPassword === confirmPassword && passwordPassword) {
      setIsPasswordUpdating(true);
      setLoading(true);
      try {
        const userCredential = await signInWithEmailAndPassword(auth, user.email, passwordPassword);
        await updatePassword(userCredential.user, newPassword);
        setPopupMessage(t('successfully_updated'));
        setNewPassword('');
        setConfirmPassword('');
        setPasswordPassword('');
      } catch (error) {
        setPopupMessage(`${t('error')}: ${error}`);
      } finally {
        setIsPasswordUpdating(false);
        setLoading(false);
        setShowPopup(true)
      }
    }
  };

  const handleDeleteAccount = async () => {
    if (deletePassword.length >= 6 && isDeleteChecked && !isDeleting) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, user.email, deletePassword);
        setIsDeleting(true)
        setLoading(true)
        const deleteUserData = httpsCallable(functions, 'deleteUserData');
        await deleteUserData({ uid: user.uid });
        alert(t('deletedSuccessfully'));
        handleLogout();
      } catch (error) {
        setPopupMessage(`${t('error')}: ${error}`);
        alert(error);
      } finally {
        setShowPopup(true)
      }
    }
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setLoading(false)
        window.location.href = '/';
    }).catch((error) => {
        console.error(t('error.logout'), error);
    });
  };    

  const closePopup = () => {
      setShowPopup(false);
  };

  if (loading) {
    return (
      <div className="loading-container-account">
        <div className="progress-bar-account"></div>
      </div>
    );
  }

  return (
    <div className="account-details-container-account">
      <div className="account-details-content-account">
        { 
          userDetails.url_image != "" ?
            <img
              src={userDetails.url_image}
              alt={t('account.altImage')}
              className="account-photo-account"
            />
          :
            <img
              src={require("../images/logo.png")}
              alt={t('account.altImage')}
              className="account-photo-account"
              style={{objectFit: 'contain'}}
            />
        }
        <input type="file" accept="image/*" onChange={handleImageChangeAccount} />
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder={t('namePlaceholderUser')}
          className="account-name-input-account"
        />
        <p className="account-email-account">
          {t('email')}: {user?.email}
        </p>
        <button
          onClick={handleUpdateAccount}
          disabled={newName.length < 3 || isUpdating}
          className="update-button-account"
        >
          {isUpdating ? t('updating') : t('update')}
        </button>

        {/* Seção de atualização de e-mail */}
        <div className="update-email-section-account">
          <h2 className="update-email-title-account">{t('updateEmailTitle')}</h2>
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder={t('newEmailPlaceholder')}
            className="update-email-input-account"
          />
          <input
            type="email"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
            placeholder={t('confirmEmailPlaceholder')}
            className="update-email-input-account"
          />
          <input
            type="password"
            value={passwordEmail}
            onChange={(e) => setPasswordEmail(e.target.value)}
            placeholder={t('currentPassword')}
            className="update-email-input-account"
          />
          <button
            onClick={handleEmailUpdateAccount}
            disabled={isEmailUpdating || !newEmail || !confirmEmail || !passwordEmail || newEmail !== confirmEmail || passwordEmail.length < 6}
            className="email-update-button-account"
          >
            {isEmailUpdating ? t('updating') : t('update')}
          </button>
        </div>

        {/* Seção de atualização de senha */}
        <div className="update-email-section-account">
          <h2 className="update-email-title-account">{t('updatePasswordTitle')}</h2>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder={t('newPasswordPlaceholder')}
            className="update-email-input-account"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder={t('confirmPasswordPlaceholder')}
            className="update-email-input-account"
          />
          <input
            type="password"
            value={passwordPassword}
            onChange={(e) => setPasswordPassword(e.target.value)}
            placeholder={t('currentPassword')}
            className="update-email-input-account"
          />
          <button
            onClick={handlePasswordUpdateAccount}
            disabled={isPasswordUpdating || !newPassword || !confirmPassword || !passwordPassword || newPassword !== confirmPassword || passwordPassword.length < 6}
            className="email-update-button-account"
          >
            {isPasswordUpdating ? t('updating') : t('update')}
          </button>
        </div>

        {/* Seção de exclusão de conta */}
        <div className="update-email-section-account">
          <h2 className="update-email-title-account" style={{ color: 'red' }}>{t('deleteAccount')}</h2>
          <input
            type="password"
            value={deletePassword}
            onChange={(e) => setDeletePassword(e.target.value)}
            placeholder={t('currentPassword')}
            className="update-email-input-account"
          />
          <label>
              <input
                  type="checkbox"
                  checked={isDeleteChecked}
                  onChange={() => setIsDeleteChecked(!isDeleteChecked)}
                  />
              <span>
                {t('confirmDeleteText')}
              </span>
          </label>
          <button
            onClick={handleDeleteAccount}
            disabled={deletePassword.length < 6 || !isDeleteChecked || isDeleting}
            className="delete-button-account"
            style={{marginTop: 30}}
          >
            {t('deleteAccount')}
          </button>
        </div>
      </div>

                
      {showPopup && (
          <div className="popup">
              <div className="popup-content">
                  <p>{popupMessage}</p>
                  <button onClick={closePopup} className="close-popup">{t('close')}</button>
              </div>
          </div>
      )}
    </div>
  );
};

export default AccountDetails;
